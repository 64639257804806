import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment} from '../../environments/environment';
import { UserRegisterModel} from '../groups-models/user-register.model';
import { UserLoginModel} from '../groups-models/user-login.model'
const API_PRODUCTS_URL = environment.API_URL;

// Real REST API
@Injectable()
export class BookingService {

	constructor(private http: HttpClient) { }

  // registerNewUser(model: UserRegisterModel): Observable<any> {
	// 	return this.http.post(API_PRODUCTS_URL + '/Accounts/register', model);
  // }
  // loginUser(model: UserLoginModel): Observable<any> {
	// 	return this.http.post(API_PRODUCTS_URL + '/Accounts/login', model);
  // }


  createNewUser(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Account/MakeBookingRequest',obj);
  }

  loginUser(obj): Observable<any[]> {
    return this.http.post<any[]>(API_PRODUCTS_URL + '/Account/Login' , obj);
  }

  makeBookingRequest(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/MakeBookingRequest',obj);
  }

  confirmBookingRequest(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/ConfirmBookingRequest',obj);
  }

  confirmBookingPayment(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/ConfirmBookingPayment',obj);
  }

  rejectBookingRequest(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/RejectBookingRequest',obj);
  }

  sendBookingInvoice(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/SendBookingInvoice',obj);
  }

  removeSlotFromrequest(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/RemoveSlotFromRequest',obj);
  }

  removeRequest(obj) : Observable<any> {
    return this.http.post(API_PRODUCTS_URL + '/Booking/RemoveRequest',obj);
  }

	getBlocksByRowId(id): Observable<any[]> {
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetBlockListByRowId/' + id);
  }

  getPendingPaymentRequestsList(): Observable<any[]> {
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetInvoiceSentBookingRequestsList');
  }
  getNewRequestsList(): Observable<any[]> {
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetNewBookingRequestsList' );
  }
  getConfirmedBookingRequestsList(): Observable<any[]> {
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetCompletedBookingRequestsList');
  }

  getSlotsByBlockId(id): Observable<any[]> {
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetSlotsByBlockId/' + id);
  }

  getSlotsDetailsByBlockId(id):Observable<any[]>{
    return this.http.get<any[]>(API_PRODUCTS_URL + '/Booking/GetSlotsDetailByBlockId/' + id);
  }

  // getGroupsList(model: GroupModel,userid): Observable<any> {
	// 	return this.http.put(API_PRODUCTS_URL + 'User/UpdateUser/'+ userid, model);
  // }






}
