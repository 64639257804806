import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GroupsPublicModule} from './groups-public/groups-public.module';
import { GroupsPrivateModule} from './groups-private/groups-private.module';
import { BookingService} from './groups-services/groups-services';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AngularWebStorageModule } from 'angular-web-storage';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularWebStorageModule,
    ToastrModule.forRoot()
    ],
  providers: [BookingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
