
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';


const routes: Routes = [
  { path: '', redirectTo: 'public', pathMatch: 'full' },
  { path: '', loadChildren: './groups-public/groups-public.module#GroupsPublicModule', data: { preload: true } },
  { path: '', loadChildren: './groups-private/groups-private.module#GroupsPrivateModule', data: { preload: true } },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
